body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  height: 75px;
  margin: 0;
  padding: 10px 0;
  color: rgba(255,255,255,.9);
}

nav a {
  color: rgba(255,255,255,.9);
  text-decoration: none;
}

nav ul {
  margin: 13px 0 15px 8px;
  float: right;
  padding-inline-start: 5px;
}

nav li {
  list-style-type: none;
  text-align: right;
}

@media only screen and (min-width: 481px) {  
  nav {
    height: 50px;
  }
  
  nav ul {
    margin: 15px;
  }

  nav li {
    display: inline;
  }
}

nav a:hover {
  text-decoration: underline;
}

nav h1 {
  margin: 5px;
  float: left;
}

.gradient-background {
  background: rgb(18,128,42);
  background: linear-gradient(90deg, rgba(18,128,42,1) 10%, rgba(118,222,141,1) 50%, rgba(18,128,42,1) 90%);
}

/* For mobile phones: */
.contentbox {
  margin: 0 auto;
  padding: 20px 0;
  max-width: 90%;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .contentbox {
    max-width: 75%;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .contentbox {
    max-width: 50%;
  }
}

.section{
  margin: 0;
  padding: 10px;
}

.centered {
  margin: 0 auto;
  text-align: center;
}

.socialbuttons {
  margin: 0;
  padding: 20px;
}

.social {
  color: black;
  margin: 0 5px;
}

.divider {
  border: 2px dashed rgb(18,128,42);
  width: 50%;
  margin: auto;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-container > div {
  padding-top: 10px;
}

@media only screen and (min-width: 673px) {
  .flex-container {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .flex-container > div {
    padding-top: 0;
  }
}

.portrait {
  vertical-align: bottom;
}

.banner {
  margin: 0 auto;
  width: 80%;
}

.section a {
  color: black;
}

/*.anchor { 
  margin-top: -30px;
  padding-bottom: 30px; 
  display: block;
}*/